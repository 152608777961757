$druid: #FF7C0A;
$hunter: #AAD372;
$mage: #3FC7EB;
$paladin: #F48CBA;
$priest: #FFFFFF;
$rogue: #FFF468;
$shaman: #0070DD;
$warlock: #8788EE;
$warrior: #C69B6D;
$deathknight: #C41F3B;

$nature: #429c5c;
$shadow: #c072dd;
$frost: #55BBFF;
$fire: #FF0000;
$arcane: #DDBBFF;
$holy: #FFFFAA;

$poor: #889D9D;

$boss-kill-colour: #429c5c;
$boss-wipe-colour: #fd4646;
$trash-colour: #84bdf8;
$idle-colour: #333333;