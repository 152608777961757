@import "../_colours.scss";

@mixin coloriseSummary($colour) {
    th {
        background-color: #000000;
        color: $colour;
    }

    td {
        background-color: scale-color($colour, $alpha: -50%);
    }
}

div.fightChart {
    display: flex;

    div.fightSummary {
        margin-top: 15px;
        margin-right: 10px;

        th.name {
            width: 70px;
            text-align: right;
            padding-right: 10px;
        }

        td.time {
            width: 50px;
            text-align: right;
            padding-right: 10px;
        }

        td.percentage {
            width: 40px;
            text-align: right;
            padding-right: 10px;
        }

        tr.boss-kill {
            @include coloriseSummary($boss-kill-colour);
        }

        tr.boss-wipe {
            @include coloriseSummary($boss-wipe-colour);
        }

        tr.trash {
            @include coloriseSummary($trash-colour);
        }

        tr.idle {
            @include coloriseSummary($idle-colour);
        }

        tr.total {
            background-color: #000000;
        }
    }

    svg {
        rect.boss-kill, text.boss-kill {
            fill: $boss-kill-colour;
        }

        rect.boss-wipe, text.boss-wipe {
            fill: $boss-wipe-colour;
        }

        rect.trash, text.trash {
            fill: $trash-colour;
        }

        rect.idle {
            fill: $idle-colour;
        }

        text.idle {
            fill: #FFFFFF;
        }
    }
}