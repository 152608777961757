@import "../_colours.scss";

@mixin character-block($class-color) {
    height: 25px;

    &_Summary {
        background-color: scale-color($class-color, $alpha: -50%);

        th.name {
            text-align: left;
        }
    }

    td.name {
        color: $class-color;
    }

    td.center:not(.classbg) {
        color: $class-color;
    }

    td.classbg {
        background-color: scale-color($class-color, $alpha: -75%);
    }

    td.enchants {
        //width: 65px;
        color: $class-color;
        text-align: center;

        &.good {
            color: #1eff00;
        }

        &.average {
            color: #FFFF00;
        }

        &.bad {
            color: #FF7700;
        }

        &.missing {
            color: #FF0000;
        }
    }

    td.gems {
        background-color: scale-color($class-color, $alpha: -75%);
        text-align: center;
    }

    td.cooldowns {
        background-color: scale-color($class-color, $alpha: -75%);
        text-align: center;
    }

    td.deaths {
        background-color: scale-color($class-color, $alpha: -75%);
        text-align: center;
    }

    td.world_buff.summary {
        color: $class-color;
    }

    td.world_buffs {
        background-color: scale-color($class-color, $alpha: -75%);
        text-align: center;

        &.count {
            width: 30px;
        }

        &.uptime {
            width: 45px;
        }
    }

    td.protection_potion_summary {
        text-align: center;
    
        .amount {
            width: 55px;
        }

        &:not(.amount) {
            width: 55px;
        }
    }

    td.dispels {
        background-color: scale-color($class-color, $alpha: -75%);
        text-align: center;

        &.summary {
            width: 50px;
        }

        &:not(.summary) {
            width: 25px;
        }
    }

    td.interrupts {
        text-align: center;

        &.summary {
            width: 50px;
        }

        &:not(.summary) {
            width: 25px;
        }
    }

    td.even_column_group {
        background-color: scale-color($class-color, $alpha: -75%);
    }

    .class-colour1 {
        background-color: $class-color;
    }
    
    .class-colour2 {
        background-color: scale-color($class-color, $alpha: -25%);
    }

    .class-colour3 {
        background-color: scale-color($class-color, $alpha: -50%);
    }

    .class-colour4 {
        background-color: scale-color($class-color, $alpha: -75%);
    }

    .percentage {
        width: 32px;
    }
}

.bad1 {
    background-color: $boss-wipe-colour;
}

.bad2 {
    background-color: scale-color($boss-wipe-colour, $alpha: -50%);
}

.good1 {
    background-color: $boss-kill-colour;
}

.good2 {
    background-color: scale-color($boss-kill-colour, $alpha: -50%);
}

tr.Druid {
    @include character-block($druid);
}

tr.Hunter {
    @include character-block($hunter);
}

tr.Mage {
    @include character-block($mage);
}

tr.Paladin {
    @include character-block($paladin);
}

tr.Priest {
    @include character-block($priest);
}

tr.Rogue {
    @include character-block($rogue);
}

tr.Shaman {
    @include character-block($shaman);
}

tr.Warlock {
    @include character-block($warlock);
}

tr.Warrior {
    @include character-block($warrior);
}

tr.DeathKnight {
    @include character-block($deathknight);
}

tr.Summary {
    @include character-block($poor);
}

@mixin protection-potion($school-colour) {
    background-color: scale-color($school-colour, $alpha: -75%);
    text-align: center;
    
    .amount {
        width: 50px;
    }

    &:not(.amount) {
        width: 25px;
    }
}

tr.grid_header {
    height: 30px;

    th.WorldBuffs {
        min-width: 120px;
    }

    th.ProtPotions {
        min-width: 120px;
    }

    th.Deaths {
        min-width: 65px;
    }
}

tr.error {
    background-color: scale-color(#FF0000, $alpha: -75%);
}

td.nature.protection_potion {
    @include protection-potion($nature);
}

td.fire.protection_potion {
    @include protection-potion($fire);
}

td.frost.protection_potion {
    @include protection-potion($frost);
}

td.shadow.protection_potion {
    @include protection-potion($shadow);
}

td.arcane.protection_potion {
    @include protection-potion($arcane);
}

td.holy.protection_potion {
    @include protection-potion($holy);
}

td.priest.protection_potion {
    @include protection-potion($priest);
}

td.world_buff {
    width: 30px;
    text-align: right;
    padding-right: 5px;

    &.died {
        color: #FFC7CE;
    }

    &.buff_limit {
        color: #FFEB9C;
    }

    &.time_out {
        color: #C6EFCE;
    }
}

td {
    border: solid 1px black;
    border-collapse: collapse;
    margin: 0;
    min-width: 25px;
}

td.center {
    text-align: center;
}

td.right {
    text-align: right;
    padding-right: 5px;
}

tr.even {
    background-color: #111111;
}

table.grid_table {
    border-spacing: 0;
    margin-left: 10px;

    margin: 5px;
    background-color: #222;
}

td.center {
    text-align: center;
}

td.right {
    text-align: right;
    padding-right: 5px;
}

div.boss_nav {
    margin-top: 10px;
    display: flex;

    div.separator {
        &:after {
            margin: 5px;
            font-size: 20px;
            line-height: 40px;
            border-right: solid 1px #333333;
            content: ' '
        }
    }

    a {
        text-decoration: none;

        &.selected > div.boss_fight {
            background-color: #333333;
            color: #FFFFFF;
        }

        &:first-child > div.boss_fight {
            min-height: 111px;
        }
    }

    div.boss_fight {
        width: 128px;
        border-radius: 5px;
        border: solid 1px #333333;
        cursor: pointer;
        text-align: center;
        position: relative;

        img {
            max-width: 128px;
        }

        &:hover, &.selected {
            background-color: #333333;
            color: #FFFFFF;
        }

        div.boss_hardmode {
            position: absolute;
            right: 0;
        }

        div.boss_name {
            text-align: center;
            height: 34px;
        }
    }

    div.boss_percentage {
        margin-top: 5px;
        height: 5px;
        
        &.kill {
            background-color: #429c5c;
        }

        &.wipe {
            background-color: #fd4646;
        }
    }
}

@mixin class_hover($class_color) {
    background-color: scale-color($class_color, $alpha: -50%);
    color: #FFFFFF !important;
}

@mixin class_nav($class_color) {
    &.class_nav {
        padding: 5px;
        margin: 5px;
        min-width: 70px;
        max-width: 100px;
        border: solid 1px #333333;
        border-radius: 5px;
        color: $class_color;
        cursor: pointer;

        img.spell_icon {
            margin-right: 5px;
        }

        &:hover {
            @include class_hover($class_color);
        }
    }
}

div.nav_bar {
    display: flex;

    a {
        text-decoration: none;

        &.selected {
            div.Tank {
                @include class_hover($frost);
            }

            div.DPS {
                @include class_hover($fire);
            }

            div.Healer {
                @include class_hover($nature);
            }

            div.warrior {
                @include class_hover($warrior);
            }

            div.rogue {
                @include class_hover($rogue);
            }

            div.hunter {
                @include class_hover($hunter);
            }

            div.mage {
                @include class_hover($mage);
            }

            div.warlock {
                @include class_hover($warlock);
            }

            div.priest {
                @include class_hover($priest);
            }

            div.paladin {
                @include class_hover($paladin);
            }

            div.shaman {
                @include class_hover($shaman);
            }

            div.druid {
                @include class_hover($druid);
            }

            div.deathknight {
                @include class_hover($deathknight);
            }
        }

        div.Tank {
            @include class_nav($frost);
        }

        div.DPS {
            @include class_nav($fire);
        }

        div.Healer {
            @include class_nav($nature);
        }

        div.warrior {
            @include class_nav($warrior);
        }
    
        div.rogue {
            @include class_nav($rogue);
        }
    
        div.hunter {
            @include class_nav($hunter);
        }
    
        div.mage {
            @include class_nav($mage);
        }
    
        div.warlock {
            @include class_nav($warlock);
        }
    
        div.priest {
            @include class_nav($priest);
        }
    
        div.paladin {
            @include class_nav($paladin);
        }
    
        div.shaman {
            @include class_nav($shaman);
        }
    
        div.druid {
            @include class_nav($druid);
        }

        div.deathknight {
            @include class_nav($deathknight);
        }

        div.All {
            @include class_nav($poor);
        }
    }

    div.separator {
        &:after {
            margin: 5px;
            font-size: 20px;
            line-height: 40px;
            border-right: solid 1px #333333;
            content: ' '
        }
    }
}

tr.loading {
    td:not(.name):after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }
}
  
@keyframes ellipsis {
to {
    width: 1.25em;    
}
}

@-webkit-keyframes ellipsis {
to {
    width: 1.25em;    
}
}

.expand_toggle {
    cursor: pointer;
    border: solid 1px #555555;
    border-radius: 50%;
    margin: 5px;
    min-width: 20px;
    display: inline-block;
    font-size: larger;
    font-family: Arial, Helvetica, sans-serif;

    &:hover {
        background-color: #555555;
    }
}

.even-colgroup {
    background-color: #111111;
}

th.trash {
    img.spell_icon {
        filter: grayscale(100%);
    }
}

th.empty {
    color: #FF0000;
}

th.common {
    color: #FFFFFF;
}

th.uncommon {
    color: #1eff00;
}

th.rare {
    color: #0070dd;
}

th.epic {
    color: #a335ee;
}

a {
    color: #BBBBBB;
}

th.enchants.good {
    color: #1eff00;
}

th.enchants.bad {
    color: #FF7700;
}

th.enchants.average {
    color: #FFFF00;
}

th.enchants.missing {
    color: #FF0000;
}

th.arcane {
    color: $arcane;
}

th.fire {
    color: $fire;
}

th.frost {
    color: $frost;
}

th.nature {
    color: $nature;
}

th.shadow {
    color: $shadow;
}

.icon_count {
    position: relative;
    margin-right: 2px;

    &:after {
        content: attr(data-count);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: white;
        font-weight: bold;
        margin-top: 2px;
        text-shadow: 1px 1px black
    }
}

.icon_highlight {
    position: relative;
    margin-right: 2px;

    &:after {
        content: ' ';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: white;
        font-weight: bold;
        margin-top: 2px;
        border-radius: 2px;
    }

    &.good {
        &:after {
            background-color: #00FF0033;
        }
    }

    &.average {
        &:after {
            background-color: #FFFF0055;
        }
    }

    &.bad {
        &:after {
            background-color: #ff990055;
        }
    }

    &.missing {
        &:after {
            background-color: #FF000033;
        }
    }
}

svg {
    margin-top: 10px;
}

.grid-bar-column {
    display: flex;
}

img.spell_icon {
    width: 17px;
    height: 17px;
    vertical-align: middle;
}

a.external {
    img {
        height: 16px;
        width: 16px;
        margin-right: 5px;
    }
    &:after {
        content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA70lEQVQ4jcWPMVLDQBAEe2Q/yhQR/MHEDskom0Cne4BvFdgUOfAA/AdS6RNEvENaEnAdKpVEBBNd7e3M9MJ/S98PM3sCNsAiXwghqK7rV3dfD+cAy2y2AR7dvR22dF33IOkk6Ra4zv/ygIW7t1VVnYYBMcbGzHbAFfCWhyyHy2NKKW2BA1C5+7ukc0AxZzaznaQDcB9CsKIoPiSdKScJ8uYQwhGgLMsWuJklSCltJR2/zJbN12bmkwT7/f4iwz5OUY4SxBibvu8v58yTJ8QYmzkz/Dyhk7RKKU0aJK2AbizgBbiTtJsp7SQ9/4bub/QJa/9UXUTXyz4AAAAASUVORK5CYII=");
        line-height: 1em;
        margin: 0 3px 0 5px;
        vertical-align: bottom;
    }
}